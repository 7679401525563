// STYLE VARIABLES
//--------------------------
//Colors
//text
$text-color:#18182B;
$text-light: #8E8F96;
$selected-category: #F85741;

//purples
$dark-pr-color:#2E3283;
$strong-pr-color: #7275C6;
$medium-pr-color: #9498F6;
$light-pr-color: rgb(210 211 243);


//greys
$inactive-grey: #ebecf4;
$grid-grey: #D7D7E1;
$grid-strong: #18182B;
$line-color: #bbbdff;

//background
$background-color:#edeef9;
$background-color-extra-light:#f3f3f9;

//Table
$cell-height: 20px;
$table-border: '1.5px' solid $dark-pr-color;

//BASIC COMPONENTS
//-------------------------
%regular-text {
    color: $text-color;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

%button {
    padding:5px;
    cursor:pointer;
    border-radius: 2px;
    font-size: 16px;
    width: auto;
    border:1px solid $strong-pr-color;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend %regular-text;
}
