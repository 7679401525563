@import 'variables';

//Main

body{
    overflow-x: hidden; /* Deactivates horizontal scrolling */
    font-size: 14px;
}

/* Increase font size for screens wider than 1024px */
@media screen and (min-width: 1366px) {
    body {
      font-size: 16px;
    }
  }
  
  /* Further increase font size for even larger screens */
  @media screen and (min-width: 1600px) {
    body {
      font-size: 18px;
    }
  }

.main {
    width: 100vw;
    height:100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-height: 100vh;
    background: #fff;

    .grid-half{
        text-align: left;  
        display: grid;
        grid-template-columns: 2vw 60vw 36vw 2vw;
        height: 100vh;
    }

    .background{
        grid-column: 1/3;
        grid-row: 1/2;
        background-color: #f1f1f7;
    }
    
    .plot {
        text-align: center;  
        grid-column: 2;
        grid-row: 1/2;
        background: #ffffff;
        border: 1px dashed $grid-grey;
        // max-width:768px;
        // max-height: 531px;
        place-self: center;
        // position:relative;
        margin-right: 2vw;
    }

    .questions {
        grid-column: 3;
        grid-row: 1;
        height: auto;
        margin-left: 2vw;
        margin-right: 2vw;
        margin-top:4vh;

        p{
            font-size:1em;
        }

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;

            .timer {
                font-weight: 400;
              }
              
              .timer.red {
                color: red;
              }
          }
    }


    .buttons{
        position:absolute;
        left: 64vw;
        bottom: 5vh;
    }

    .welcome {
        height: 100%;
        width:90vw;
        padding-top:2vh;
        margin-left:5vw;
        margin-right:5vw;
        padding-bottom: 2vh;

        * {
            box-sizing: border-box;
            max-width: 100%;
        }

        .info-page{
            width:100%;
            margin-top: 3vh;
            margin-bottom:3vh;
        }

        p {
            font-size: 1em;
        }

        li {
            font-size: 1em;
        }

        ul {
            margin:0;
        }
    }

    .theEnd {
        p {
            font-size: 1em;
        }

        li {
            font-size: 1em;
        }
    }

    .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Full viewport height */
        text-align: center;
        color: $text-color;
      }
      
      .center h1 {
        font-size: 3em;
        margin-bottom: 0.5em;
      }
      
      .center hr {
        width: 50%;
        margin: 1em 0;
      }
      
      .center h4 {
        font-size: 1.5em;
        margin-top: 0.5em;
      }
}


// text
h3{
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.5em;
}

h4{
    font-size: 1.5em;
}

h5{
    font-size: 1.2em;
    margin-bottom: 0;
}

h1{
    margin-bottom: 0;
    margin-top: 0;
    font-size: 2em;
}

label{
    font-size: 0.8em;
    font-weight: 500;
    color: #8e8f96;
}

h2{
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.8em;
}

p{
    margin: 4px;
    font-size: 1em;
}

.selected-category {
    color:$selected-category;
    font-weight: 700;
}



// buttons

button {
    @extend %button;
}

.large {
    color:white;
    background-color:  $strong-pr-color;
    padding:10px;
    margin-top:20px;
    @extend %button;
}

.no-button{
    background-color:  $text-light;
    border-color: $text-light; 
    margin-right: 1vw;
}

.large:hover  {
    margin-top:20px;
    padding:10px;
    background-color: $dark-pr-color;
}

.large.disabled {
    background-color:  $grid-grey;
    color:white;
    border-color: $grid-grey;
    cursor: not-allowed;;
    padding:10px;
    margin-top:20px;
    @extend %button;
}

.large.hide {
    opacity:0;
}

.primary.enabled {
    color:white;
    background-color:  $strong-pr-color;
    @extend %button;
}

.primary.enabled:hover {
    background-color: $dark-pr-color;
}

.outline {
    background: transparent;
    margin-left:15px;
    border: 2px solid $strong-pr-color;
    color: $strong-pr-color;
    font-weight: 500;
    padding: 5px;
    @extend %button;
}

.outline:hover{
    border: 2px solid $dark-pr-color;
    color: $dark-pr-color;
}

.group-buttons {
    display: flex;
    justify-content: end;
}

.space-in-between-buttons {
    display: flex;
    justify-content: space-between;

    #prev{
        position:absolute;
        z-index:2;
        bottom:48vh;
        left:2vw;
    }

    #next{
        position:absolute;
        z-index:2;
        bottom:48vh;
        right:2vw;
    }
}
// visualizations
    .grid {
        color: $grid-strong;
        opacity: 0.5;
        stroke-width: 0.5px;
    }

    .grid-strong {
        color: $grid-strong;
        stroke-width: 1px;
    }

    .strongText {
      font-size: 14px;
      font-weight: bold;
    }

    .strongSSBText {
        font-size: 14px;
        color: $text-color;
        font-weight: bold;
    }

    .lightText {
      font-size: 12px;
      color: $text-light;
    }

    .dashed-line {
      stroke: grey;
      stroke-width: 2;
      stroke-dasharray: 4;
    }


    //likert

    .likert-scale {
        list-style-type: none;
        padding: 0;

        table {
            width: 100%;
            border-collapse: collapse;
        }

        th, td {
            font-size: 1em;
            font-weight: 500;
            text-align: left;
        }

        input[type="radio"] {
            margin-top:6px;
        }
    }


    .horizontal {
        th, td {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    


    // carousel

    .carousel {
        position: relative;
        width: 100%;
        min-height: 85vh;
        overflow: hidden;
        align-content: center;
      }
      

      
      .carousel-item {
        display: flex;
        justify-content: center; /* Center horizontally */
        margin-left:7vw;
        margin-right:7vw;
    }
    
    .carousel-item img {
        max-width: 100%;
        height: auto;
    }

    .img-explanation{
        display:grid;
        grid-template-columns: 65% 30%;
        place-items: center;

        .w-65{
            grid-column:1;
        }

        .gif{
            border: 3px dashed $grid-grey;
        }

        .w-35 {
            grid-column: 2;
            margin-left:20px;
        }
    }

    .link-button{
        cursor:pointer;
        color: $strong-pr-color;
        font-weight: bold;
        text-decoration: underline;
    }

    .correctValue{
        border: 1px solid $medium-pr-color;
        padding:5px;
        margin-right: 1vw;
        background-color: #dedef8;
        border-radius: 4px;

    }

    .hr-white{
        border-top: 1px solid $medium-pr-color;
    }

    .centered-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5px 0;
    
        span {
            margin-bottom: 10px;
        }
    
        .stars-container {
            display: flex;
            justify-content: center;
    
            .star {
                color: #7275c6;
                font-size: 1.8em;
                margin: 0 2px;
            }
        }
    }

    .input-container {
        display: flex;
        align-items: center;
      }
      
      .input-field {
        width: 120px;
        height: 30px;
        padding: 5px;
        box-sizing: border-box;
        margin-right: 8px; /* Space between input and select */
      }
      
      .select-field {
        height: 30px;
        padding: 5px;
        box-sizing: border-box;
      }


      /* For Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    text-align: center;
    font-size: 2.5vmin;
    padding: 20px;
  }










  
  
  
  
 
  

  
  
  
  
